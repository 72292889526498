import React from 'react';
import Helmet from 'react-helmet';

export function TitleAndMetaTags({ url, pathname, title, description }) {
	return (
		<Helmet>
			<title>
				{title} – {description}
			</title>

			<meta property="og:url" content={`${url}/${pathname}`} />
			<meta property="og:image" content={`${url}/social/hero-1.jpg`} />
			<meta property="og:title" content={title} />
			<meta property="og:description" content={description} />

			<meta name="theme-color" content="#f44336"/>
			<meta name="msapplication-navbutton-color" content="#f44336"/>
			<meta name="apple-mobile-web-app-status-bar-style" content="#f44336"/>

			<link href={`/icons/favicon.ico`} rel="shortcut icon"/>
        	<link href={`/icons/touch.png`} rel="apple-touch-icon-precomposed"/>

		</Helmet>
	);
}

TitleAndMetaTags.defaultProps = {
	url: 'https://giovedipizza.com',
	pathname: '',
	title: 'Giovedì Pizza',
	description: 'Antenna tra il silenzio e Dio.'
};
